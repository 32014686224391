import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import Map from '../images/about-map.png';
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isSingleLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],
            isCategoryLoaded: false,
            categoryContent: [],
            additional_fields: null
        }
    }

    async componentDidMount() {
        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let CMSId = id.params["*"];
        this.getPageDetailsById();
        // this.getAllCategoryByType();
        this.getAllBlog();
    }

    componentWillReceiveProps(nextProps) {
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let id = nextProps.params["*"];
        this.getPageDetailsById();
        // this.getAllCategoryByType();
        this.getAllBlog();
    }

    getAllBlog() {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/award")
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data;
                    console.log("filteredDates", filteredDates);
                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    getPageDetailsById() {
        fetch("https://tatva.com/backendapi/api/v1/cms/602b59324a831648cdc13839")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isSingleLoaded: true,
                        items: result,
                    });
                },
                (error) => {
                    this.setState({
                        isSingleLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isSingleLoaded, isBlogLoaded, items, blogContent, isCategoryLoaded, categoryContent, additional_fields } = this.state;

        return (
            <Layout>
                
                {isSingleLoaded ?
                    <Nav 
                        active="about"
                        pageTitle={items.data?.title}
                        title={items.data?.document_title}
                        description={items.data?.meta_description}
                        keywords={items.data?.meta_tag}
                    ></Nav>
                    : ''
                }

                <Intro title="About Us" description="Tatva Legal, Hyderabad is the largest full-service law firm in the states of Telangana and Andhra Pradesh, and has consistently been ranked as the best regional law firm in India over the last seven years. We combine our local market insights with legal expertise to help our national and international clients in their domestic and cross-border endeavours."> </Intro>

                <div className="main-container" id="main-container-about">
                    <div id="aboutus">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="blog-container">
                                        <div className="row justify-content-center" id="AwardAbout">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="home-intro-awards">
                                                    <div className="home-intro-awards1">
                                                        <ul>
                                                            {isBlogLoaded ?
                                                                <React.Fragment>
                                                                    {blogContent.map(item => (
                                                                        <React.Fragment>
                                                                            <li>{item.short_description}</li>
                                                                        </React.Fragment>

                                                                    ))}
                                                                </React.Fragment>
                                                                : ''}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center" id="ensuring_timely">
                                            <div className="col-md-12 col-sm-12 text-center">
                                                <div className="inner">
                                                    By ensuring timely and highest quality advice, we remain the first port of call for enterprises doing business in the states of Telangana and Andhra Pradesh
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center" id="award_map">
                                            <div className="col-md-12 col-sm-12 text-center">
                                            <div className="inner">
                                                <img src={Map} title="" alt="" />
                                            </div>
                                        </div>
                                        </div>

                                        <div className="row justify-content-center" id="award_intro">
                                            <div className="col-md-12 col-sm-12 text-center">
                                            <div className="inner">
                                                <h4>Awarded the Best Regional Law Firm in India several years in a row</h4>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-center" id="award_list">
                                            <div className="col-md-12 col-sm-12 text-center">
                                            <div className="inner">
                                                <ul className="award_list">
                                                    {isBlogLoaded ?
                                                        <React.Fragment>
                                                            {blogContent.map(item => (
                                                                <React.Fragment>
                                                                    <li>
                                                                        <div className="year year1">{item.short_description}</div>
                                                                        <h3>{item.title}</h3>
                                                                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                                    </li>
                                                                </React.Fragment>

                                                            ))}
                                                        </React.Fragment>
                                                        : ''}
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;
